import React from "react"
import styled from "styled-components"

import RelatedPostCard from "./RelatedPostCard"

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const CategoryTitle = styled.h2`
  font-size: medium;
  border-bottom: 1px solid rgb(213, 220, 225);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  margin-bottom: 15px !important;
  span {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white none repeat scroll 0% 0%;
    padding: 0px 10px;
  }
`

const CategorySection = ({ title, posts }) => (
  <>
    <CategoryTitle>
      <span>{title}</span>
    </CategoryTitle>
    <PostsGrid>
      {posts.map(edge => (
        <RelatedPostCard
          key={edge.node.frontmatter.slug}
          slug={edge.node.frontmatter.slug}
        />
      ))}
    </PostsGrid>
  </>
)

export default CategorySection
