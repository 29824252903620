import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

import MyImg from "../components/MyImg";

const RelatedPostLink = styled(Link)`
  display: block;
  border-radius: 4px;
  background: rgb(241, 243, 245) none repeat scroll 0% 0%;
  overflow: hidden;
`;

const RelatedImg = styled.figure`
  position: relative;
  margin: 0px;
  width: 100%;
  overflow: hidden;
`;

const RelatedPostDetails = styled.p`
  font-size: small;
  padding: 15px;
  text-align: center;
  line-height: 1.5em;
  color: rgb(29, 43, 54);
  margin: 0px;
  span {
    display: block;
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    color: rgb(147, 161, 176);
    font-size: 9.5px;
    font-weight: 500;
  }
`;

const renderPost = post => (
  <RelatedPostLink to={`/blog/${post.node.frontmatter.slug}`}>
    <RelatedImg>
      <MyImg src={post.node.frontmatter.thumbnail} />
    </RelatedImg>
    <RelatedPostDetails>
      <span>{post.node.frontmatter.category}</span>
      {post.node.frontmatter.title}
    </RelatedPostDetails>
  </RelatedPostLink>
);

const RelatedPostCard = ({ slug }) => (
  <StaticQuery
    query={graphql`
      query {
        posts: allMarkdownRemark {
          edges {
            node {
              frontmatter {
                title
                thumbnail
                posted_at(formatString: "MMMM D, YYYY")
                slug
                category
              }
            }
          }
        }
      }
    `}
    render={({ posts }) => {
      return renderPost(
        posts.edges.find(post => post.node.frontmatter.slug === slug)
      );
    }}
  />
);

export default RelatedPostCard;
