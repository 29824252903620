import React, { Component } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import CategorySection from "../components/CategorySection"

const CategoryContainer = styled.section`
  margin-bottom: 15px;
`

const CategoryTitle = styled.h1`
  text-align: center;
`

class CategoriesPage extends Component {
  render() {
    return (
      <>
        <SEO title="Categories" />
        <CategoryTitle>Categories</CategoryTitle>
        {this.props.data.allMarkdownRemark.group.map(group => (
          <CategoryContainer key={group.edges[0].node.frontmatter.category}>
            <CategorySection
              title={group.edges[0].node.frontmatter.category}
              posts={group.edges}
            />
          </CategoryContainer>
        ))}
      </>
    )
  }
}

export default CategoriesPage

export const CategoriesQuery = graphql`
  query CategoriesQuery {
    allMarkdownRemark(filter: { frontmatter: { category: { ne: "" } } }) {
      group(field: frontmatter___category) {
        edges {
          node {
            id
            frontmatter {
              category
              slug
              title
              thumbnail
              posted_at(formatString: "MMM YYYY")
              description
            }
          }
        }
      }
    }
  }
`
